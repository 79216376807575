<template>
  <div>
<!--    <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters justify="center">-->
<!--      <v-col class="text-right" cols="12">-->
<!--        <v-btn class="history-tab-mobile" :color="selectedTab == 'DEPOSIT' ? 'primary' : ''" depressed @click="onChangeTab('DEPOSIT')">-->
<!--          <deposit-history-active class="mobile-icon" v-if="selectedTab == 'DEPOSIT'"></deposit-history-active>-->
<!--          <deposit-history class="mobile-icon" v-else></deposit-history>-->
<!--          <span v-if="selectedTab == 'DEPOSIT'">{{ $t(`label.deposit`) }}</span>-->
<!--        </v-btn>-->
<!--        <v-btn class="history-tab-mobile" :color="selectedTab == 'WITHDRAWAL' ? 'primary' : ''" depressed @click="onChangeTab('WITHDRAWAL')">-->
<!--          <withdrawal-history class="mobile-icon" :class="selectedTab == 'WITHDRAWAL' ? 'mobile-icon-active' : ''"></withdrawal-history>-->
<!--          <span v-if="selectedTab == 'WITHDRAWAL'">{{ $t(`label.withdrawal`) }}</span>-->
<!--        </v-btn>-->
<!--        <v-btn class="history-tab-mobile" :color="selectedTab == 'TRANSFER' ? 'primary' : ''" depressed @click="onChangeTab('TRANSFER')">-->
<!--          <transfer-history class="mobile-icon" :class="selectedTab == 'TRANSFER' ? 'mobile-icon-active' : ''"></transfer-history>-->
<!--          <span v-if="selectedTab == 'TRANSFER'">{{ $t(`label.transfer`) }}</span>-->
<!--        </v-btn>-->
<!--        <v-btn class="history-tab-mobile" :color="selectedTab == 'REBATE' ? 'primary' : ''" depressed @click="onChangeTab('REBATE')">-->
<!--          <rebate-history class="mobile-icon" :class="selectedTab == 'REBATE' ? 'mobile-icon-active' : ''"></rebate-history>-->
<!--          <span v-if="selectedTab == 'REBATE'">{{ $t(`button.rebate`) }}</span>-->
<!--        </v-btn>-->
<!--        &lt;!&ndash;       Bonus history         &ndash;&gt;-->
<!--        <v-btn class="history-tab-mobile" :color="selectedTab == 'BONUS' ? 'primary' : ''" depressed @click="onChangeTab('BONUS')">-->
<!--          <rebate-history class="mobile-icon" :class="selectedTab == 'BONUS' ? 'mobile-icon-active' : ''"></rebate-history>-->
<!--          <span v-if="selectedTab == 'BONUS'">{{ $t(`label.bonus`) }}</span>-->
<!--        </v-btn>-->
<!--        &lt;!&ndash;       Bonus history         &ndash;&gt;-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row class="filter-card-wrapper hidden-md-and-up no-gutters">
      <v-col cols="12" class="d-flex wrapper-col">
        <div>
          <v-card text :ripple="false" depressed class="game-menu-content" :class="[this.selectedTab == 'DEPOSIT' ? 'selected' : '']" @click="onChangeTab('DEPOSIT')">
            <div class="toStatus-card-image mx-auto" :style="`--src:url('/static/svg/mobileHistory/depoHistory.svg')`" :class="[this.selectedTab == 'DEPOSIT' ? 'selected' : '']">
              <img :url="`/static/svg/mobileHistory/depoHistory.svg`" class="" />
            </div>
            <div class="game-menu-title">{{ $t(`label.deposit`) }}</div>
          </v-card>
        </div>
        <div>
          <v-card text :ripple="false" depressed class="game-menu-content" :class="[this.selectedTab == 'WITHDRAWAL' ? 'selected' : '']" @click="onChangeTab('WITHDRAWAL')">
            <div class="toStatus-card-image mx-auto" :style="`--src:url('/static/svg/mobileHistory/withdrawHistory.svg')`" :class="[this.selectedTab == 'WITHDRAWAL' ? 'selected' : '']">
              <img :url="`/static/svg/mobileHistory/withdrawHistory.svg`" class="" />
            </div>
            <div class="game-menu-title">{{ $t(`label.withdrawal`) }}</div>
          </v-card>
        </div>
        <div>
          <v-card text :ripple="false" depressed class="game-menu-content"
                  :class="[this.selectedTab == 'TRANSFER' ? 'selected' : '']" @click="onChangeTab('TRANSFER')">
            <div class="toStatus-card-image mx-auto" :style="`--src:url('/static/svg/mobileHistory/transferHistory.svg')`" :class="[this.selectedTab == 'TRANSFER' ? 'selected' : '']">
              <img :url="`/static/svg/mobileHistory/transferHistory.svg`" class="" />
            </div>
            <div class="game-menu-title">{{ $t(`label.transfer`) }}</div>
          </v-card>
        </div>
        <div>
          <v-card text :ripple="false" depressed class="game-menu-content"
                  :class="[this.selectedTab == 'REBATE' ? 'selected' : '']" @click="onChangeTab('REBATE')">
            <div class="toStatus-card-image mx-auto" :style="`--src:url('/static/svg/mobileHistory/rebateHistory.svg')`" :class="[this.selectedTab == 'REBATE' ? 'selected' : '']">
              <img :url="`/static/image/turnover/toRunning.png`" class="" />
            </div>
            <div class="game-menu-title">{{ $t(`button.rebate`) }}</div>
          </v-card>
        </div>
        <div>
          <v-card text :ripple="false" depressed class="game-menu-content"
                  :class="[this.selectedTab == 'BONUS' ? 'selected' : '']" @click="onChangeTab('BONUS')">
            <div class="toStatus-card-image mx-auto" :style="`--src:url('/static/svg/mobileHistory/promoHistory.svg')`" :class="[this.selectedTab == 'BONUS' ? 'selected' : '']">
              <img :url="`/static/image/turnover/toRunning.png`" class="" />
            </div>
            <div class="game-menu-title">{{ $t(`label.bonus`) }}</div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-card class="desktop_card" elevation="0">
      <v-card-title class="justify-space-between" v-if="$vuetify.breakpoint.mdAndUp">
        <label class="--v-primary-base text-left">{{ $t(`label.history`) }}</label>
      </v-card-title>
      <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters justify="end" class="px-10">
        <v-col class="text-right" cols="12">
          <v-btn class="history-tab" :color="selectedTab == 'DEPOSIT' ? 'primary' : ''" depressed @click="onChangeTab('DEPOSIT')">
            <deposit-history-active class="mobile-icon" v-if="selectedTab == 'DEPOSIT'"></deposit-history-active>
            <deposit-history class="mobile-icon" v-else></deposit-history>
            {{ $t(`label.deposit`) }}
          </v-btn>
          <v-btn class="history-tab" :color="selectedTab == 'WITHDRAWAL' ? 'primary' : ''" depressed @click="onChangeTab('WITHDRAWAL')">
            <withdrawal-history class="mobile-icon" :class="selectedTab == 'WITHDRAWAL' ? 'mobile-icon-active' : ''"></withdrawal-history>
            {{ $t(`label.withdrawal`) }}
          </v-btn>
          <v-btn class="history-tab" :color="selectedTab == 'TRANSFER' ? 'primary' : ''" depressed @click="onChangeTab('TRANSFER')">
            <transfer-history class="mobile-icon" :class="selectedTab == 'TRANSFER' ? 'mobile-icon-active' : ''"></transfer-history>
            {{ $t(`label.transfer`) }}
          </v-btn>
          <v-btn class="history-tab" :color="selectedTab == 'REBATE' ? 'primary' : ''" depressed @click="onChangeTab('REBATE')">
            <rebate-history class="mobile-icon" :class="selectedTab == 'REBATE' ? 'mobile-icon-active' : ''"></rebate-history>
            {{ $t(`button.rebate`) }}
          </v-btn>
          <!--       Bonus history         -->
          <v-btn class="history-tab" :color="selectedTab == 'BONUS' ? 'primary' : ''" depressed @click="onChangeTab('BONUS')">
            <rebate-history class="mobile-icon" :class="selectedTab == 'BONUS' ? 'mobile-icon-active' : ''"></rebate-history>
            {{ $t(`label.bonus`) }}
          </v-btn>
          <!--       Bonus history         -->
        </v-col>
      </v-row>
      <v-card class="bank-detail-card history-card" elevation="0">
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
          <v-col cols="12" v-if="selectedTab == 'TRANSFER'">
            <v-data-table light :headers="transferHeaders" :items="transactionList" class="elevation-0" :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers" :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                  <th class="referral-table-header text-end" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          clearable
                          @click:clear="clearFilter()"
                          outlined
                          class="date-search ml-3 elevation-0"
                          hide-details="true"
                          v-on="on"
                          append-icon="search"
                        ></v-text-field>
                      </template>
                      <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
                    </v-menu>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.created_at | longDate }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.from_wallet }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.to_wallet }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'FAILED'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'SUCCESS'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status == 'PENDING'">watch_later</v-icon>
                    <span v-if="item.status == 'FAILED'">{{ $t(`transactionStatus.fail`) }}</span>
                    <span v-if="item.status == 'SUCCESS'">{{ $t(`transactionStatus.success`) }}</span>
                    <span v-if="item.status == 'PENDING'">{{ $t(`transactionStatus.pending`) }}</span>
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" v-if="selectedTab == 'REBATE'">
            <v-row no-gutters class="pa-3" align="center" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
              <v-col cols="4" class="text-center pr-2">
                <label class="referral-table-header subtitle-1">{{ $t(`label.totalRebateUnclaimed`) }}{{ totalRebate.unclaimed | currency(currentCurrency) }}</label>
              </v-col>
              <v-col cols="3" class="text-center pr-2">
                <label class="referral-table-header subtitle-1">{{ $t(`label.totalRebateAmt`) }}{{ totalRebate.total | currency(currentCurrency) }}</label>
              </v-col>
              <v-col cols="1">
                <v-btn
                  height="auto"
                  v-if="totalRebate.unclaimed > 0"
                  @click="claimRebate()"
                  color="black"
                  depressed
                  class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text"
                >
                  {{ $t(`label.claim`) }}
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateRangeText"
                      clearable
                      @click:clear="clearFilter()"
                      outlined
                      class="date-search ml-3 elevation-0"
                      hide-details="true"
                      v-on="on"
                      append-icon="search"
                    ></v-text-field>
                  </template>
                  <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-data-table light :headers="rebateHeaders" :items="transactionList" class="elevation-0" :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers" :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                </tr>
              </template>

              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bet_time_end | longDate }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.valid_bet_amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.vip_percentage }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.rebate_amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.provider_code }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.game_type }}
                  </td>
                  <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.fk_rebate_status_id == 1 ? $t(`label.claimed`) : $t(`label.unclaimed`) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" v-if="selectedTab == 'DEPOSIT'">
            <v-data-table light :headers="depositHeaders" :items="transactionList" class="elevation-0" :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers" :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                  <th class="referral-table-header text-end" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          clearable
                          @click:clear="clearFilter()"
                          outlined
                          class="date-search ml-3 elevation-0"
                          hide-details="true"
                          v-on="on"
                          append-icon="search"
                        ></v-text-field>
                      </template>
                      <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
                    </v-menu>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.date | longDate }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.method }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bonus_name ? item.bonus_name : '-' }}
                  </td>
                  <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'Approved and completed'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">watch_later</v-icon>
                    <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                    <span v-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                    <span v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">{{ $t(`transactionStatus.pending`) }}</span>
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.remarks ? item.remarks : '-' }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" v-if="selectedTab == 'WITHDRAWAL'">
            <v-data-table light :headers="withdrawalHeaders" :items="transactionList" class="elevation-0" :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers" :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                  <th class="referral-table-header text-end" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          clearable
                          @click:clear="clearFilter()"
                          outlined
                          class="date-search ml-3 elevation-0"
                          hide-details="true"
                          v-on="on"
                          append-icon="search"
                        ></v-text-field>
                      </template>
                      <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
                    </v-menu>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.date | longDate }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.method }}
                  </td>
                  <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'Approved and completed'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">watch_later</v-icon>
                    <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                    <span v-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                    <span v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">{{ $t(`transactionStatus.pending`) }}</span>
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.remarks ? item.remarks : '-' }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <!-- Bonus history -->
          <v-col cols="12" v-if="selectedTab == 'BONUS'">
            <v-data-table light :headers="bonusHeaders" :items="transactionList" class="elevation-0" :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers" :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                  <th class="referral-table-header text-end" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          clearable
                          @click:clear="clearFilter()"
                          outlined
                          class="date-search ml-3 elevation-0"
                          hide-details="true"
                          v-on="on"
                          append-icon="search"
                        ></v-text-field>
                      </template>
                      <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
                    </v-menu>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bonus.created_at | longDate }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bonus.name }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.ref_id }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bonus_given_amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'Canceled'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'Success'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending'">watch_later</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending CS'">watch_later</v-icon>
                    <span v-if="item.status == 'Canceled'">{{ $t(`bonusStatus.canceled`) }}</span>
                    <span v-if="item.status == 'Success'">{{ $t(`bonusStatus.success`) }}</span>
                    <span v-if="item.status == 'Pending'">{{ $t(`bonusStatus.pending`) }}</span>
                    <span v-if="item.status == 'Pending CS'">{{ $t(`bonusStatus.pendingCustomerService`) }}</span>
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <!-- End bonus history -->
        </v-row>
        <v-row no-gutters v-else>
          <v-col cols="12" class="pt-4 pb-2 px-4">
            <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeText"
                  clearable
                  @click:clear="clearFilter()"
                  color="buttonPrimary"
                  outlined
                  class="date-search-mobile mb-3 elevation-0"
                  hide-details="true"
                  v-on="on"
                  append-icon="search"
                ></v-text-field>
              </template>
              <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="this.selectedTab == 'DEPOSIT'">
            <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in depositHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.date | longDate }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.method }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    <label>{{ item.bonus_name ? item.bonus_name : '-' }}</label>
                  </v-col>
                  <v-col cols="12">
                    <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                    <span v-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                    <span v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">{{ $t(`transactionStatus.pending`) }}</span>
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'Approved and completed'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">watch_later</v-icon>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ item.remarks ? item.remarks : '-' }}</label>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="this.selectedTab == 'WITHDRAWAL'">
            <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in withdrawalHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.date | longDate }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.method }}
                  </v-col>
                  <v-col cols="12">
                    <span v-if="item.status == 'Rejected and Complete'">{{ $t(`transactionStatus.fail`) }}</span>
                    <span v-if="item.status == 'Approved and completed'">{{ $t(`transactionStatus.success`) }}</span>
                    <span v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">{{ $t(`transactionStatus.pending`) }}</span>
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'Rejected and Complete'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'Approved and completed'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status != 'Rejected and Complete' && item.status != 'Approved and completed'">watch_later</v-icon>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ item.remarks ? item.remarks : '-' }}</label>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="this.selectedTab == 'TRANSFER'">
            <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in transferHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.created_at | longDate }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.from_wallet }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.to_wallet }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    <span v-if="item.status == 'FAILED'">{{ $t(`transactionStatus.fail`) }}</span>
                    <span v-if="item.status == 'SUCCESS'">{{ $t(`transactionStatus.success`) }}</span>
                    <span v-if="item.status == 'PENDING'">{{ $t(`transactionStatus.pending`) }}</span>
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'FAILED'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'SUCCESS'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status == 'PENDING'">watch_later</v-icon>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="this.selectedTab == 'REBATE'">
            <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in rebateHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.bet_time_end | longDate }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.valid_bet_amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.vip_percentage }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.rebate_amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.provider_code }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.game_type }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.fk_rebate_status_id == 1 ? $t(`label.claimed`) : $t(`label.unclaimed`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <!-- Bonus history -->
          <v-col cols="12" v-if="this.selectedTab == 'BONUS'">
            <v-row v-for="(item, index) in transactionList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in bonusHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.bonus.created_at | longDate }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.bonus.name }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.ref_id }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.bonus_given_amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    <span v-if="item.status == 'Canceled'">{{ $t(`bonusStatus.canceled`) }}</span>
                    <span v-if="item.status == 'Success'">{{ $t(`bonusStatus.success`) }}</span>
                    <span v-if="item.status == 'Pending'">{{ $t(`bonusStatus.pending`) }}</span>
                    <span v-if="item.status == 'Pending CS'">{{ $t(`bonusStatus.pendingCustomerService`) }}</span>
                    <v-icon color="red" class="mb-1" small v-if="item.status == 'Canceled'">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.status == 'Success'">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending'">watch_later</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.status == 'Pending CS'">watch_later</v-icon>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <!-- End bonus history -->
        </v-row>
        <v-row>
          <v-col cols="12" v-if="transactionList.length == 0" class="text-center pt-0 pb-8">
            <label class="referral-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</label>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-1" v-if="pagination.last_page > 1">
        <v-col cols="12" class="text-center">
          <v-pagination
            class="trxn_history_pagination"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="changePage"
            :next-icon="'arrow_forward_ios'"
            :prev-icon="'arrow_back_ios'"
            :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import depositHistoryActive from '@/assets/svg/deposit-history-active.svg'
import depositHistory from '@/assets/svg/deposit-history.svg'
import withdrawalHistory from '@/assets/svg/withdrawal-history.svg'
import transferHistory from '@/assets/svg/transfer-history.svg'
import rebateHistory from '@/assets/svg/rebate-history.svg'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import _ from 'lodash'
import { REBATE_HISTORY, CLAIM_REBATE, BONUS_HISTORY } from '@/store/bonus.module'
import { TRANSACTION_HISTORY } from '@/store/transfer.module'
import { PAYMENT_HISTORY } from '@/store/payment.module'
export default {
  name: 'transactionHistory',
  components: {
    depositHistory,
    depositHistoryActive,
    withdrawalHistory,
    transferHistory,
    rebateHistory
  },
  data: () => ({
    dateMenu: false,
    dates: ['', ''],
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'DEPOSIT',
    msgDialog: {
      show: false
    },
    inboxList: [],
    announcementList: [],
    selectedList: [],
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    searchCriteria: {
      pageNumber: 1,
      pageSize: 20,
      mobilePageSize: 10
    },
    depositHeaders: [
      {
        text: 'date',
        align: 'start',
        sortable: true,
        value: 'date',
        sortBy: 'desc'
      },
      {
        text: 'depositMethod',
        value: 'membercode',
        sortable: false
      },
      {
        text: 'depositAmt',
        value: 'membercode',
        sortable: false
      },
      {
        text: 'bonus',
        value: 'membercode',
        sortable: false
      },
      {
        text: 'status',
        value: 'status',
        sortable: false
      },
      {
        text: 'remarks',
        value: 'remarks',
        sortable: false
      }
    ],
    withdrawalHeaders: [
      {
        text: 'date',
        align: 'start',
        sortable: true,
        value: 'date',
        sortBy: 'desc'
      },
      {
        text: 'withdrawalAmt',
        value: 'membercode',
        sortable: false
      },
      {
        text: 'paymentMethod',
        value: 'commission',
        sortable: false
      },
      {
        text: 'status',
        value: 'status',
        sortable: false
      },
      {
        text: 'remarks',
        value: 'remarks',
        sortable: false
      }
    ],
    transferHeaders: [
      {
        text: 'transactionDate',
        align: 'start',
        sortable: true,
        value: 'created_at',
        sortBy: 'desc'
      },
      {
        text: 'fromWallet',
        value: 'membercode',
        sortable: false
      },
      {
        text: 'toWallet',
        value: 'commission',
        sortable: false
      },
      {
        text: 'amount',
        value: 'status',
        sortable: false
      },
      {
        text: 'status',
        value: 'status',
        sortable: false
      }
    ],
    rebateHeaders: [
      {
        text: 'date',
        align: 'start',
        sortable: true,
        value: 'bet_time_end',
        sortBy: 'desc'
      },
      {
        text: 'valid',
        value: 'valid_bet_amount',
        sortable: false
      },
      {
        text: 'rebate',
        value: 'vip_percentage',
        sortable: false
      },
      {
        text: 'rebateAmount',
        value: 'rebate_amount',
        sortable: false
      },
      {
        text: 'provider',
        value: 'activity',
        sortable: false
      },
      {
        text: 'category',
        value: 'provider_code',
        sortable: false
      },
      {
        text: 'status',
        value: 'status',
        sortable: false
      }
    ],
    bonusHeaders: [
      {
        text: 'date',
        align: 'start',
        sortable: true,
        value: 'bonusApplyDate',
        sortBy: 'desc'
      },
      {
        text: 'bonusType',
        value: 'name',
        sortable: false
      },
      {
        text: 'bonusID',
        value: 'refId',
        sortable: false
      },
      {
        text: 'bonusHistoryAmount',
        value: 'bonusGivenAmount',
        sortable: false
      },
      {
        text: 'status',
        value: 'status',
        sortable: false
      }
    ],
    transactionList: [],
    selectedPage: 1
  }),
  created() {
    this.getPaymentHistory(this.selectedTab)
    this.getBonusHistory()
  },
  computed: {
    dateRangeText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        let endDate = this.dates[1] ? this.dates[1] : ''
        return startDate + ' - ' + endDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    paymentHistory() {
      return this.$store.state.payment.paymentHistory.data
    },
    paymentHistoryPagination() {
      return this.$store.state.payment.paymentHistory.pagination
    },
    transactionHistory() {
      return this.$store.state.transfer.transactionHistory.data
    },
    transactionHistoryPagination() {
      return this.$store.state.transfer.transactionHistory.pagination
    },
    rebateHistory() {
      return this.$store.state.bonus.rebateHistory.data
    },
    rebateHistoryPagination() {
      return this.$store.state.bonus.rebateHistory.pagination
    },
    bonusHistory() {
      return this.$store.state.bonus.bonusHistory.data
    },
    bonusHistoryPagination() {
      return this.$store.state.bonus.bonusHistory.pagination
    },
    totalRebateAmt() {
      if (this.selectedTab == 'REBATE') {
        let amt = 0
        this.$store.state.bonus.rebateHistory.data.forEach(b => {
          amt += b.rebate_amount
        })
        return amt
      }
      return 0
    },
    totalRebatePercentage() {
      if (this.selectedTab == 'REBATE') {
        let amt = 0
        this.$store.state.bonus.rebateHistory.data.forEach(b => {
          amt += b.vip_percentage
        })
        return amt
      }
      return 0
    },
    totalRebate() {
      return this.$store.state.bonus.rebateHistory.totalRebate
    },
    claimRebateResult() {
      return this.$store.state.bonus.claimRebateResult.complete
    }
  },
  watch: {
    paymentHistory() {
      this.getTransactionList()
    },
    transactionHistory() {
      this.getTransactionList()
    },
    rebateHistory() {
      this.getTransactionList()
    },
    bonusHistory() {
      this.getTransactionList()
    },
    claimRebateResult() {
      if (this.$store.state.bonus.claimRebateResult.complete) {
        this.claimRebateResponse(this.$store.state.bonus.claimRebateResult)
      }
    }
  },
  methods: {
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        let self = this
        setTimeout(function() {
          self.dateMenu = false
        }, 500)
      }
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
      if (this.selectedTab == 'DEPOSIT') {
        this.getPaymentHistory(this.selectedTab)
      } else if (this.selectedTab == 'WITHDRAWAL') {
        this.getPaymentHistory(this.selectedTab)
      } else if (this.selectedTab == 'TRANSFER') {
        this.getTransferHistory()
      } else if (this.selectedTab == 'REBATE') {
        this.getRebateHistory()
      } else {
        this.getBonusHistory()
      }
    },
    onChangeTab(type, filterDate) {
      this.selectedTab = type
      if (!filterDate) {
        this.dates = ['', '']
      }
      this.selectedPage = 1
      if (this.selectedTab == 'DEPOSIT') {
        this.getPaymentHistory(type)
      } else if (this.selectedTab == 'WITHDRAWAL') {
        this.getPaymentHistory(type)
      } else if (this.selectedTab == 'TRANSFER') {
        this.getTransferHistory()
      } else if (this.selectedTab == 'REBATE') {
        this.getRebateHistory()
      } else {
        this.getBonusHistory()
      }
    },
    getBonusHistory(type) {
      let bonusHistoryObj = {
        type: type,
        overallStatus: 'history',
        fromDate: this.dates[0],
        toDate: this.dates[1],
        page: this.selectedPage,
        itemsPerPage: 10
      }
      this.$store.dispatch(`${BONUS_HISTORY}`, {
        bonusHistoryObj
      })
    },
    getPaymentHistory(type) {
      let obj = {
        type: type,
        startTime: this.dates[0],
        endTime: this.dates[1],
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${PAYMENT_HISTORY}`, {
        obj
      })
    },
    getTransferHistory() {
      let obj = {
        startTime: this.dates[0],
        endTime: this.dates[1],
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${TRANSACTION_HISTORY}`, {
        obj
      })
    },
    getRebateHistory(type) {
      let obj = {
        type: type,
        startTime: this.dates[0],
        endTime: this.dates[1],
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REBATE_HISTORY}`, {
        obj
      })
    },
    getTransactionList() {
      if (this.selectedTab == 'DEPOSIT') {
        this.transactionList = _.cloneDeep(this.paymentHistory)
        this.pagination = this.paymentHistoryPagination
      } else if (this.selectedTab == 'WITHDRAWAL') {
        this.transactionList = _.cloneDeep(this.paymentHistory)
        this.pagination = this.paymentHistoryPagination
      } else if (this.selectedTab == 'TRANSFER') {
        this.transactionList = _.cloneDeep(this.transactionHistory)
        this.pagination = this.transactionHistoryPagination
      } else if (this.selectedTab == 'REBATE') {
        this.transactionList = _.cloneDeep(this.rebateHistory)
        this.pagination = this.rebateHistoryPagination
      } else {
        this.transactionList = _.cloneDeep(this.bonusHistory)
        this.pagination = this.bonusHistoryPagination
      }
    },
    clearFilter() {
      this.dates = []
      this.onChangeTab(this.selectedTab)
    },
    filterListByDate() {
      this.onChangeTab(this.selectedTab, true)
    },
    claimRebate() {
      //this.$store.state.bonus.claimRebateResult.complete = true
      this.$store.dispatch(`${CLAIM_REBATE}`)
    },
    claimRebateResponse(result) {
      if (result.success && result.code == 0) {
        this.$parent.openAlert(true, locale.getMessage(`message.rebateSubmitSuccess`))
      } else {
        this.$parent.openAlert(false, locale.getMessage(`message.vipBonusFailed`))
      }
    },
    sortTableList(obj) {
      let headers = []
      if (this.selectedTab == 'DEPOSIT') {
        headers = this.depositHeaders
      } else if (this.selectedTab == 'WITHDRAWAL') {
        headers = this.withdrawalHeaders
      } else if (this.selectedTab == 'TRANSFER') {
        headers = this.transferHeaders
      } else if (this.selectedTab == 'REBATE') {
        headers = this.rebateHeaders
      } else {
        headers = this.bonusHeaders
      }
      let sortBy = _.filter(headers, function(d) {
        return d.sortable
      })
      if (obj) {
        let selected = _.find(sortBy, function(d) {
          if (d.value == obj.value) {
            d.sortBy = d.sortBy == 'asc' ? 'desc' : 'asc'
            return true
          }
        })
      }
      this.transactionList = _.orderBy(this.transactionList, _.map(sortBy, 'value'), _.map(sortBy, 'sortBy'))
    }
  }
}
</script>

<style lang="scss">
.filter-card-wrapper {
  max-width: 959px;
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  background-color: var(--v-text-base);
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: flex-start;
  padding: 12px 12px 12px 12px;

  .wrapper-col{
    gap: .5rem;
  }

  .game-menu-content.v-card {
    //display:flex;
    min-width: 50px;
    width: 65px;
    height: 65px;
    text-transform: capitalize;
    padding: 12px 0px 10px !important;
    background-color: var(--v-newMobileSecondaryDarker-base);
    color: #000000;
    border-radius: 10px !important;
  }
  .toStatus-card-image {
    background-color: #000000;
    -webkit-mask-image: var(--src);
    mask-image: var(--src);
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 30px;
    height: 35px;
    margin-left: 8px;
    &.selected {
      background-color: #FFFFFF;
    }
  }
  .game-menu-title {
    text-align: center;
    font-size: 12px;
  }

  .game-menu-content {
    &.selected {
      color: #FFFFFF;
      opacity: 1;
      background: var(--v-newMobilePrimary-base);

      .game-menu-image {
        background-color: #000000;
      }
    }
  }
}

.trxn_history_pagination .v-pagination {
  li {
    border: none !important;
    button {
      border: none !important;
      font-size: 14px;
    }
  }
}
.mobile-history-table {
  .col-12 {
    min-height: 20px;
  }
}
.history-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  .date-search {
    width: fit-content;
    margin-right: 0px;
    float: right !important;
    .v-input__slot {
      min-height: 40px !important;
      max-width: 250px;
    }
    .v-input__append-inner {
      margin-top: 8px;
    }
  }
  .referral-table-header {
    color: #acacac;
  }
}
.history-tab {
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
  .mobile-icon {
    margin-right: 5px;
    width: 15px;
    height: 19px;
  }
  .mobile-icon-active {
    fill: #3a3a3a !important;
    path {
      fill: #fff !important;
    }
  }
}
.history-tab-mobile {
  width: fit-content !important;
  margin-right: 5px;
  min-width: fit-content !important;
  padding: 2px;
  background-color: #fff;
  text-transform: capitalize;
  font-size: 12px !important;
  .mobile-icon {
    margin-right: 3px;
    width: 15px;
    height: 19px;
  }
  .mobile-icon-active {
    fill: #3a3a3a !important;
    path {
      fill: #fff !important;
    }
  }
}
.date-search-mobile {
  .v-input__slot {
    min-height: 40px !important;
  }
  .v-input__append-inner {
    margin-top: 8px;
  }
}
@media (max-width: 599px){
  .filter-card-wrapper {
    .game-menu-content.v-card {
      height: 60px !important;
    }
    .toStatus-card-image {
      width: 25px !important;
      height: 30px !important;
    }
    .game-menu-title{
      font-size:.7rem;
    }
  }
}
@media (max-width: 959px) {
  .history-card {
    font-size: 12px;
    height: 80%;
    overflow-x: hidden;
    overflow-y: hidden;
    .date-search {
      width: fit-content;
      margin-right: 0px;
      float: right !important;
      .v-input__slot {
        min-height: 40px !important;
        max-width: 250px;
      }
      .v-input__append-inner {
        margin-top: 8px;
      }
    }
    .referral-table-header {
      color: #acacac;
    }
  }
}
</style>
